import { createApp, } from 'vue';
import { createPinia, } from 'pinia';
import App from './App.vue';
import router from './router';
import 'vue-global-api';
import vClickOutside from 'click-outside-vue3';
import VueDragScroller from 'vue-drag-scroller';
import FileUpload from '@yazan.alnughnugh/file-upload';
import { createYmaps, } from 'vue-yandex-maps';

// import axios, { Axios, } from 'axios';

const app = createApp(App);
const pinia = createPinia();

// app.config.globalProperties.$http = Axios;
// axios.defaults.baseURL = 'http://stem.yokel.tech/api/v1/';

// Global filters
app.config.globalProperties.$filters = {
  formatNumber(number: string | number, params = {}) {
    return Intl.NumberFormat('ru-RU', params).format(Number(number));
  },
};

// Global components
import SvgIcon from '@/components/SvgIcon.vue';
app.component('SvgIcon', SvgIcon);

// Tippy
import { plugin as VueTippy, } from 'vue-tippy';
import 'tippy.js/dist/tippy.css'; // optional for styling

app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      // appendTo: () => document.getElementById('app'),
      appendTo: () => document.getElementById('tippy-teleport'),
      placement: 'auto-end',
      allowHTML: true,
    }, // => Global default options * see all props
  }
);

export const sidebarToggler = ref(true);
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

import toast from 'vue-toastification';

app.use(toast, { position: 'bottom-right', });

// MOUNT

app.use(
  createYmaps({
    apikey: '70303425-9ae5-491a-bacd-19ae703ca0e6',
  })
);
app.component('FileUpload', FileUpload);
// app.use(auth);
app.use(VueDragScroller);
app.use(pinia);
app.use(router);
app.use(vClickOutside);
app.mount('#app');
