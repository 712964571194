<template>
  <div class="header__nav">
    <div
      v-for="link in headerLinks"
      :key="link.path"
      class="header__nav-item"
    >
      <router-link :to="link.path">
        {{ link.label }}
      </router-link>
    </div>
  </div>
</template>
<script lang="ts" setup>
const headerLinks = [
  {
    label: 'Главная',
    path: '/',
  },
  {
    label: 'Планировщик',
    path: '/planner',
  },
  {
    label: 'Заказ-наряды',
    path: '/orders',
  },
  {
    label: 'Клиенты',
    path: '/clients',
  },
  {
    label: 'Сотрудники',
    path: '/users',
  },
  {
    label: 'Запчасти',
    path: '/parts',
  },
  {
    label: 'Деньги',
    path: '/money',
  },
];
</script>

<style lang="sass" scoped>
.header__nav
  flex: 1 1 auto
  display: flex
  justify-content: space-around
  margin: -16px 40px
  grid-gap: 20px
  height: calc(100% + 32px)
  &-item
    height: 100%
    a
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      text-align: center
      font-size: 24px
      line-height: 54px
      font-weight: 500
      color: #000000
      text-decoration: none
      position: relative
      &:hover
        color: $blue

      &.router-link-active
        &:before
          content: ''
          position: absolute
          left: 0
          right: 0
          bottom: 0
          height: 4px
          border-radius: 4px 4px 0 0
          background-color: $blue


      // @media (max-width: 1800px)
      //   font-size: 18px
</style>
