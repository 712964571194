export enum EButtonTheme {
  Blue = 'blue',
  Light = 'light',
  White = 'white',
  Text = 'text',
}

export enum EButtonSize {
  Large = 'large',
  Default = 'default',
  Small = 'small',
}
