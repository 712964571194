<template>
  <div class="login-page">
    <form
      v-if="!isLoggedIn"
      class="login-page__content"
      @submit.prevent=""
    >
      <div class="login-page__form">
        <div class="login-page__form-header">
          <div class="login-page__logo">
            <img
              src="@/assets/img/logo.png"
              alt=""
            >
            автосервис
          </div>
          <div class="login-page__title">
            Вход в систему
          </div>
          <div class="title-26">
            Необходимо подключение к сети интернет
          </div>
        </div>
        <div class="login-page__form-body">
          <PSInput
            v-model="form.email"
            :theme="EInputThemes.Bordered"
            type="text"
            class="login-page__input"
            placeholder="Электронная почта"
          />
          <PSInput
            v-model="form.password"
            :theme="EInputThemes.Bordered"
            type="password"
            class="login-page__input"
            placeholder="Пароль"
          />
          <PSButton
            is-full-width
            :size="EButtonSize.Large"
            @click="onSubmit"
          >
            Войти
          </PSButton>
        </div>
        <div class="login-page__form-footer">
          <a
            href=""
            class="link"
          >Забыли пароль?</a>
        </div>
      </div>
    </form>
    <div class="login-page__footer">
      © ООО “Перегрин”, 2024 Все права защищены.
      <a
        href=""
        class="link fw-400"
      >Связаться с нами</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthStore, IUser, } from '@/store/Auth.store';

import PSInput from '@/components/PSFormElements/PSInput.vue';
import PSButton from './Buttons/PSButton.vue';
import { EButtonSize, } from './Buttons/PSButton.enum';
import { EInputThemes, } from './PSFormElements/PSInput.enum';

const authStore = useAuthStore();
const form = ref<IUser>({
  email: '',
  password: '',
});

const isLoggedIn = computed(() => useAuthStore().isLoggedIn);

// import router from '@/router';

const onSubmit = async () => {
  await authStore.login({
    email: form.value.email,
    password: form.value.password,
  });

  // const redirect =
  //   new URLSearchParams(window.location.search).get('redirect') || '/';

  // router.push(redirect);
};

// const onSubmitLogout = () => {
//   authStore.logout();
// };
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__logo {
    flex: 0 1 auto;
    font-family: sans-serif;
    font-size: min(36px, 2.4vw);
    line-height: 24px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    img {
      flex: 0 0 auto;
      margin-right: 12px;
      width: min(48px, 3.6vw);
    }
  }
  &__title {
    font-size: 60px;
    margin-bottom: 30px;
  }
  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  &__form {
    width: 620px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-header {
      width: 100%;
      margin-bottom: 60px;
    }

    &-body {
      width: 500px;
      max-width: 100%;
    }
    &-footer {
      margin-top: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &__input {
    margin-bottom: 24px;
    &:deep(input) {
      text-align: center;
    }
  }
  &__footer {
    flex: 0 0 auto;
    background-color: $light;
    text-align: center;
    padding: 30px;
  }
}
</style>
