<template>
  <div
    class="status-label"
    :style="{ width: width }"
  >
    <div
      class="status-label__marker"
      :style="'background-color: ' + getStatus.color"
    />
    <div class="status-label__text">
      {{ getStatus.text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { EStatusLabel, } from './StatusLabel.enum';
const props = withDefaults(
  defineProps<{
    status: EStatusLabel;
    width?: string;
  }>(),
  {
    status: EStatusLabel.Unknown,
  }
);

const types = {
  [EStatusLabel.InService]: {
    color: '#00A453',
    text: 'В сервисе',
  },
  [EStatusLabel.OutService]: {
    color: 'red',
    text: 'Вне сервиса',
  },
  [EStatusLabel.Weekend]: {
    color: 'red',
    text: 'Выходной',
  },
  [EStatusLabel.Working]: {
    color: '#00A453',
    text: 'Работает',
  },
  [EStatusLabel.Dismissed]: {
    color: 'red',
    text: 'Уволен',
  },
  [EStatusLabel.Unknown]: {
    color: 'gray',
    text: 'Неизвестен',
  },
};

const getStatus = computed(() => {
  return types[props.status] || EStatusLabel.Unknown;
});
</script>

<style lang="sass" scoped>
.status-label
    height: 42px
    border-radius: 21px
    padding: 16px
    background-color: $light-max
    display: inline-flex
    align-items: center

    &__marker
        margin-right: 12px
        width: 12px
        height: 12px
        border-radius: 50%

    &__text
        color: #252525
        font-size: 20px
        text-align: center
        flex-grow: 1
</style>
