<template>
  <div
    class="tag"
    :style="{ width: width }"
  >
    <template v-if="$slots.default">
      <slot />
    </template>
    <div
      v-else
      v-text="text"
    />
    <div
      v-if="withCopy"
      class="tag__copy"
      @click="onCopy"
    >
      <SvgIcon name="copy" />
    </div>
    <div
      v-if="withRemove"
      class="tag__remove"
      @click="$emit('remove')"
    >
      <SvgIcon name="close" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast, } from 'vue-toastification';

const props = defineProps<{
  text?: string;
  width?: string;
  withRemove?: boolean;
  withCopy?: boolean;
  copyText?: string;
}>();
const emit = defineEmits<{
  (e: 'remove'): void;
}>();

async function onCopy() {
  if (props.text) {
    await navigator.clipboard.writeText(props.copyText || props.text);
    useToast().success('Скопировано в буффер обмена');
  }
}
</script>

<style lang="scss" scoped>
.tag {
  padding: 10px 20px;
  color: #252525;
  font-size: 20px;
  line-height: 1;
  background-color: $light-max;
  border-radius: 20px;
  color: #252525;
  display: inline-flex;
  align-items: center;

  &__remove {
    line-height: 0;
    margin-left: 6px;
    padding: 4px;
    width: 20px;
    height: 20px;
    &:hover {
      cursor: pointer;
      :deep(svg) {
        fill: $red;
      }
    }
  }

  &__copy {
    line-height: 0;
    margin-left: 6px;
    padding: 2px;
    width: 20px;
    height: 20px;
    &:hover {
      cursor: pointer;
      :deep(svg) {
        fill: $blue;
      }
    }
  }
}
</style>
