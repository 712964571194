<template>
  <div class="header__actions">
    <div class="header__actions-item">
      <SvgIcon name="phone2" />
    </div>
    <div class="header__actions-item">
      <SvgIcon name="chat" />
    </div>
    <div class="header__actions-item active">
      <SvgIcon name="bell" />
    </div>
    <div
      class="header__actions-user"
      @click.stop="isMenuOpened = !isMenuOpened"
    >
      <SvgIcon
        v-if="!isMenuOpened"
        name="avatar"
      />
      <div
        v-else
        class="_close-menu"
      >
        <SvgIcon name="close" />
      </div>
    </div>

    <div
      v-click-outside="() => (isMenuOpened = false)"
      class="header__user-menu"
      :class="{ _opened: isMenuOpened }"
    >
      <div class="_user">
        <div class="_user-img">
          <img
            v-if="AuthStore.user.avatar"
            :src="AuthStore.user.avatar"
            alt=""
          >
          <SvgIcon
            v-else
            name="avatar"
          />
        </div>
        <div class="_user-name">
          {{ AuthStore.user?.name }} {{ AuthStore.user?.last_name }}
        </div>
        <div class="_user-role">
          {{ AuthStore.user.role }}
        </div>
      </div>
      <div class="_list">
        <template
          v-for="(item, ndx) in menuList"
          :key="ndx"
        >
          <div
            v-if="item.isSeparator"
            class="_separator"
          />

          <div
            v-else
            class="_item"
            @click="onMenuItemClick(item.action)"
          >
            <div class="_item-logo">
              <SvgIcon
                :name="item.icon?.name"
                :height="item.icon?.height"
                :width="item.icon?.width"
              />
            </div>
            <div class="_item-title">
              {{ item.title }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import router from '@/router';
import { useAuthStore, } from '@/store/Auth.store';

const AuthStore = useAuthStore();

const isMenuOpened = ref(false);

const onMenuItemClick = (callback) => {
  isMenuOpened.value = false;
  callback();
};
const menuList = [
  {
    title: 'Профиль',
    icon: {
      name: 'settings',
      width: '25px',
      height: '20px',
    },
    action: () => {
      router.push(`/users/all?id=${useAuthStore().user?.id}`);
    },
  },
  {
    title: 'Управление организацией',
    icon: {
      name: 'kanban2',
      width: '20px',
      height: '18px',
    },
    action: () => {
      router.push('/settings');
    },
  },
  {
    isSeparator: true,
  },
  {
    title: 'Выйти',
    icon: {
      name: 'exit',
      width: '18px',
      height: '20px',
    },
    action: () => {
      AuthStore.logout();
    },
  },
];
</script>

<style lang="scss" scoped>
$size: 48px;
.header__actions {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-around;
  &-item {
    margin-right: 36px;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: rgba(244, 246, 246, 1);
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon--phone2 {
      width: 30px;
      height: 30px;
    }
    .svg-icon--chat {
      width: 29px;
      height: 29px;
    }
    .svg-icon--bell {
      width: 26px * 1.8;
      height: 32px * 1.8;
    }
    &.active {
      background-color: #f00;
      :deep(.svg-icon) {
        fill: white !important;
        stroke: white !important;
      }
    }
  }
  &-user {
    user-select: none;
    cursor: pointer;
    width: $size;
    height: $size;
    background-color: rgba(244, 246, 246, 1);
    border-radius: 50%;

    :deep(svg) {
      fill: $blue;
      width: $size;
      height: $size;
    }
    ._close-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $size;
      height: $size;
      background-color: rgba(244, 246, 246, 1);
      border-radius: 50%;

      :deep(svg) {
        width: calc(#{$size} - 20px);
        height: calc(#{$size} - 20px);
        fill: $blue;
      }
    }
  }
}

.header__user-menu {
  user-select: none;
  transition: $tr;
  transform: translate(100%, 0);
  opacity: 0;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  z-index: 22;
  box-shadow: -2px 2px 4px rgba(black, 0.17);

  &._opened {
    opacity: 1;
    transform: translate(0, 0);
  }
  ._user {
    background-color: $light-active;
    padding: 20px;
    padding-left: 24px + 42px + 20px;
    position: relative;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      line-height: 0;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      @include cover;
    }
    &-name {
      font-size: 24px;
      line-height: 30px;
    }
    &-role {
      font-weight: 300;
      font-size: 16px;
    }
  }
  ._list {
    padding: 12px 20px;
    background-color: #fff;
  }
  ._item {
    position: relative;
    margin: 8px 0;
    display: flex;
    align-items: center;
    min-height: 42px;
    padding-left: 42px + 24px;
    transition: $tr;
    &:hover {
      cursor: pointer;
      color: $blue;
    }
    &-logo {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: $light-max;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      :deep(svg) {
        fill: $blue;
      }
      :deep(path) {
        fill: $blue;
      }
    }
    &-title {
    }
  }
  ._separator {
    height: 1px;
    margin: 16px 0;
    background-color: $light;
  }
}
</style>
