export default function unFormatPrice(price) {
  // console.log('🔷 ~  unFormatPrice ~ income:', price, typeof price);
  if (price === undefined || price === null) {
    return 0;
  }

  if (typeof price === 'number') {
    return price;
  }

  // console.log('🔷 ~   unFormatPrice ~ output:', price);
  return +price.replaceAll(' ', '').replaceAll(' ', '').replaceAll(',', '.');
}
