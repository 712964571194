<template>
  <svg
    v-if="name"
    :class="className"
    xmlns="http://www.w3.org/2000/svg"
    :style="{
      width: width,
      height: height,
    }"
  >
    <title v-if="title">{{ title }}</title>
    <use
      :xlink:href="iconPath"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',

  props: {
    name: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: null,
    },
    fill: {
      type: String, // "blue" | "black" | "white" |  "red"
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
  },

  computed: {
    iconPath() {
      // eslint-disable-next-line
      let icon = require(`@/assets/icons/${this.name}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }

      return icon.url;
    },

    className() {
      return (
        'svg-icon svg-icon--' +
        this.name +
        (this.fill ? ` svg-icon--fill-${this.fill}` : '')
      );
    },
  },
};
</script>

<style lang="scss">
.svg-icon {
  fill: currentColor;
  --height: auto;
  --width: auto;
  height: var(--height);
  width: var(--width);
}
.svg-icon--fill-blue {
  fill: $blue;
}
.svg-icon--fill-black {
  fill: $black;
}
.svg-icon--fill-white {
  fill: $white;
}
.svg-icon--fill-red {
  fill: $red;
}
</style>
